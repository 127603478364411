import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import { IMAGE_PROFILE_DEFAULTS } from "shared/utils/imageUrlBuilder";
import ProductImage from "../../ProductImage";

function ProductSuggestTileImage({ image, minimal }) {
  const normalProductImage = (
    <ProductImage
      image={image}
      sizes="104px"
      imageProfileDefault={IMAGE_PROFILE_DEFAULTS.pdsmain}
    />
  );
  const minimalProductImage = (
    <ProductImage
      image={image}
      preview
      imageProfileDefault={IMAGE_PROFILE_DEFAULTS.pdsmain}
    />
  );

  if (minimal) return minimalProductImage;
  return normalProductImage;
}

ProductSuggestTileImage.propTypes = {
  image: PropTypes.object,
  minimal: PropTypes.bool,
};

export default ProductSuggestTileImage;

/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */

import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classnames from "classnames/bind";
import track from "shared/tracking";
import Text from "stelar/components/Text";
import LinkButton from "stelar/components/LinkButton";
import IconRight from "stelar/components/Icon/IconRight";
import SearchBar from "stelar/components/SearchBar";
import propTypes from "./propTypes";

import s from "./InteractiveSearchForm.module.css";
import ProductSuggestTile from "../ProductSuggestTile/ProductSuggestTile";
import skeletonData from "./skeletonData";
// import { isSearchToggleActive } from "../../pages/utils/constants";
import "url-search-params-polyfill";

const EMPTY_TRACKING_VALUE = "EMPTY";
const cx = classnames.bind(s);

class InteractiveSearchForm extends React.Component {
  componentDidUpdate(prevProps) {
    const { focusedSuggestion, onProductSuggest, words } = this.props;

    if (
      focusedSuggestion !== prevProps.focusedSuggestion &&
      words[focusedSuggestion]
    ) {
      onProductSuggest(words[focusedSuggestion].word);
    }
  }

  render() {
    const {
      placeholder,
      buttonText,
      searchString,
      typedSearchString,
      link,
      words,
      wordsSectionHeading,
      focusedSuggestion,
      focusedProduct,
      products,
      productsLink,
      productsSectionHeading,
      onChange,
      onSubmit,
      onLinkClick,
      onNavigateTo,
      onSuggest,
      onClickSuggestion,
      onClearSuggests,
      onClearInput,
      onArrowNavigation,
      onHoverSuggestion,
      onLeaveSuggestion,
      onMouseEnterSubmitButton,
      productSkeleton,
      eventTracking,
      query,
    } = this.props;

    function shouldRenderSuggestions(value) {
      return value && value.trim().length >= 2 && value !== query;
    }

    function shouldRenderSuggestionOnFocus(value) {
      return value && value.trim().length >= 2;
    }

    const listedProducts = productSkeleton
      ? skeletonData.productSuggests
      : products;

    const hasWords = words.length > 0;
    const hasProxyProducts = listedProducts.length > 0;
    const hasProducts = products.length > 0;
    const hasRealData = !(
      hasWords &&
      words[0].userInputWord &&
      !hasProxyProducts
    );

    const goToSearchText = productSkeleton
      ? skeletonData.link.name
      : productsLink && productsLink.name;

    const hasSuggestions = (hasWords || hasProducts) && hasRealData;

    const {
      suggestSearchString,
      suggestSelectedProduct,
      suggestSelectedSuggestion,
    } = eventTracking;

    const selectedProductLink =
      focusedProduct !== null &&
      products[focusedProduct] &&
      products[focusedProduct].link
        ? products[focusedProduct].link.link
        : null;

    const handleChange = (event) => {
      const { value } = event.target;
      if (shouldRenderSuggestions(value)) {
        onSuggest(value);
      } else if (hasSuggestions) {
        onClearSuggests();
      }
      onChange(value);
    };

    const handleFocus = (value) => {
      if (shouldRenderSuggestionOnFocus(value)) {
        onSuggest(value);
      }
    };

    const handleClearAndTracking = () => {
      onClearInput();
      onClearSuggests();
      track({
        googleTagManager: {
          containerId: window.gtmId,
          payload: { event: "search-clear-input" },
        },
      });
    };

    function tracking(searchValue, suggestValue, productLink) {
      const transformTrackingData = (trackingData, value) => {
        if (!trackingData?.googleTagManager) {
          return;
        }

        const { containerId, event } = trackingData.googleTagManager;
        return {
          googleTagManager: {
            containerId,
            payload: {
              [event]: value,
            },
          },
        };
      };

      track(transformTrackingData(suggestSearchString, searchValue));
      track(transformTrackingData(suggestSelectedSuggestion, suggestValue));
      track(transformTrackingData(suggestSelectedProduct, productLink));
    }

    const handleClickSuggestion = (event, word) => {
      event.preventDefault();
      tracking(typedSearchString, searchString, EMPTY_TRACKING_VALUE);
      onChange(word);
      onClickSuggestion(word);
      onClearSuggests();
    };

    const handleClickProduct = (e, productLink) => {
      onLinkClick(e);
      tracking(
        typedSearchString,
        typedSearchString === searchString
          ? EMPTY_TRACKING_VALUE
          : searchString,
        productLink
      );

      onClearSuggests();
      onClearInput();
    };

    function handleSubmit(e) {
      if (focusedProduct !== null) {
        e.preventDefault();
        onNavigateTo(selectedProductLink);
        onClearInput();
      } else {
        setTimeout(onClearSuggests, 1000);
        onSubmit(e);
      }

      tracking(
        typedSearchString,
        typedSearchString === searchString
          ? EMPTY_TRACKING_VALUE
          : searchString,
        focusedProduct === null ? EMPTY_TRACKING_VALUE : selectedProductLink
      );

      onClearSuggests();
    }

    function renderSuggestionTitle(headline) {
      if (!headline) return null;
      return (
        <div className={s.headline}>
          <Text em>{headline}</Text>
        </div>
      );
    }

    const inputProps = {
      placeholder,
      value: searchString,
      onMouseEnter: onMouseEnterSubmitButton,
      onChange: handleChange,
      onFocus: () => handleFocus(searchString),
      onClickClear: handleClearAndTracking,
      onKeyDown: (e) => {
        if (
          (e.key === "ArrowDown" ||
            e.key === "ArrowUp" ||
            e.key === "ArrowLeft" ||
            e.key === "ArrowRight") &&
          hasSuggestions
        ) {
          if (
            (focusedSuggestion > 0 && focusedProduct !== null) ||
            e.key === "ArrowDown" ||
            e.key === "ArrowUp"
          ) {
            e.preventDefault();
          }
          onArrowNavigation(e.key);
        }

        if (e.key === "Escape") {
          onClearSuggests();
        }
      },
    };

    const isCloseButton = true;
    const inputComponent = (
      <div className={cx("input", { hasSuggestions })}>
        <SearchBar
          {...inputProps}
          aria-autocomplete="list"
          aria-controls="InterActiveSearchForm__list"
          buttonText={buttonText}
          data-test-sell-search-input
          isCloseButtonActive={isCloseButton}
        />
      </div>
    );

    return (
      <form
        method="GET"
        action={link}
        onSubmit={handleSubmit}
        ref={(node) => {
          // eslint-disable-next-line react/no-unused-class-component-methods
          this.searchForm = node;
        }}
        role="search"
        data-test-sell-search-form
      >
        {hasSuggestions && (
          <div className={s.closepane} onClick={onClearSuggests} />
        )}
        {inputComponent}
        <div
          className={cx("suggestionsContainer", { hidden: !hasSuggestions })}
          id="InterActiveSearchForm__list"
          role="listbox"
        >
          {hasSuggestions && (
            <div className={s.overlay} onMouseLeave={onLeaveSuggestion}>
              <div className={cx("words", "sectionContainer")}>
                {hasWords && (
                  <div className={s.sectionInner}>
                    {renderSuggestionTitle(wordsSectionHeading)}
                    <ul className={s.list}>
                      {words.map(({ word, userInputWord }, index) => (
                        <li className={s.element}>
                          <Text normal key={word}>
                            <a
                              href={`/suche/?q=${word}`}
                              data-prio2
                              className={cx("suggestion", {
                                focused: focusedSuggestion === index,
                              })}
                              type="button"
                              data-test-sell-search-suggest
                              key={word}
                              onClick={(e) => handleClickSuggestion(e, word)}
                              onMouseEnter={() => onHoverSuggestion(index)}
                              aria-controls="InterActiveSearchForm__products"
                            >
                              {userInputWord ? `»${word}«` : word}
                              <div className={s.icon}>
                                {focusedSuggestion === index && (
                                  <IconRight tiny />
                                )}
                              </div>
                            </a>
                          </Text>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              {hasProxyProducts && (
                <div
                  className={cx("products", "sectionContainer")}
                  id="InterActiveSearchForm__products"
                  role="listbox"
                >
                  <div className={s.sectionInner}>
                    {renderSuggestionTitle(
                      `${productsSectionHeading} »${searchString}«`
                    )}
                    <ul className={s.list}>
                      {listedProducts.map((product, index) => (
                        <li className={s.element}>
                          <ProductSuggestTile
                            {...product}
                            image={productSkeleton ? {} : product.image}
                            onLinkClick={(e) =>
                              handleClickProduct(e, product.link.link)
                            }
                            key={product.link && product.link.link}
                            selected={focusedProduct === index}
                            skeleton={productSkeleton}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              {hasProxyProducts && (
                <div className={s.bottomLink}>
                  <LinkButton
                    em
                    prio1
                    type="submit"
                    icon={<IconRight small />}
                    iconPosition="right"
                    skeleton={productSkeleton}
                    onMouseEnter={onMouseEnterSubmitButton}
                  >
                    {goToSearchText}
                  </LinkButton>
                </div>
              )}
            </div>
          )}
        </div>
      </form>
    );
  }
}

InteractiveSearchForm.propTypes = propTypes;

InteractiveSearchForm.defaultProps = {
  words: [],
  products: [],
  eventTracking: {},
};

export default InteractiveSearchForm;

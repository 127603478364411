import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import Text from "stelar/components/Text";
import classnames from "classnames/bind";
import s from "./ProductSuggestTileText.module.css";

const cx = classnames.bind(s);

function ProductSuggestTileText({
  name,
  manufacturer,
  nameAdditional,
  skeleton,
}) {
  return (
    <div className={cx("root", { skeleton })}>
      {manufacturer ? (
        <div className={s.manufacturer}>
          <Text light skeleton={skeleton}>
            {manufacturer}
          </Text>
        </div>
      ) : null}
      <div className={s.name}>
        <Text normal skeleton={skeleton}>
          {name}
        </Text>
        {nameAdditional ? (
          <Text normal skeleton={skeleton}>
            {` ${nameAdditional}`}
          </Text>
        ) : null}
      </div>
    </div>
  );
}

ProductSuggestTileText.propTypes = {
  name: PropTypes.string,
  manufacturer: PropTypes.string,
  nameAdditional: PropTypes.string,
  skeleton: PropTypes.bool,
};

export default ProductSuggestTileText;

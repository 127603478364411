import PropTypes from "prop-types";
import React from "react"; // eslint-disable-line import/no-extraneous-dependencies
import classnames from "classnames/bind";
import { buildUrl } from "shared/utils/routing";
import s from "./ProductSuggestTile.module.css";
import ProductSuggestTileImage from "./ProductSuggestTileImage";
import ProductSuggestTileText from "./ProductSuggestTileText";

const cx = classnames.bind(s);

function ProductSuggestTile({
  image,
  link,
  manufacturer,
  minimal,
  name,
  nameAdditional,
  onLinkClick,
  selected,
  skeleton,
}) {
  return (
    <div
      className={cx("root", { selected })}
      data-test-sell-product-suggest-tile
    >
      <a
        href={buildUrl(link)}
        className={s.link}
        onClick={onLinkClick}
        data-test-sell-product-suggest-tile-link
      >
        <div className={s.box}>
          {image && (
            <div className={s.imagebox}>
              <ProductSuggestTileImage image={image} minimal={minimal} />
            </div>
          )}
          {(name || manufacturer || nameAdditional) && (
            <div className={s.textbox}>
              <ProductSuggestTileText
                name={name}
                manufacturer={manufacturer}
                nameAdditional={nameAdditional}
                skeleton={skeleton}
              />
            </div>
          )}
        </div>
      </a>
    </div>
  );
}

ProductSuggestTile.propTypes = {
  image: PropTypes.object,
  link: PropTypes.object,
  manufacturer: PropTypes.string,
  minimal: PropTypes.bool,
  name: PropTypes.string,
  nameAdditional: PropTypes.string,
  onLinkClick: PropTypes.func,
  selected: PropTypes.bool,
  skeleton: PropTypes.bool,
};

export default ProductSuggestTile;

const linkDTO = (index) => ({
  link: `/superunique-link${index}`,
});
const product1 = {
  image: {},
  link: linkDTO("1"),
  manufacturer: "",
  name: "Skeleton Jakob Fischer",
};
const product2 = {
  image: {},
  link: linkDTO("2"),
  manufacturer: "Canisius Henssen",
  name: "Skeleton-Rüben-Kraut",
};
const product3 = {
  image: {},
  link: linkDTO("3"),
  manufacturer: "",
  name: "Skeleton Red Sentinel",
};
export default {
  productSuggests: [product1, product2, product3],
  link: { name: "alle 10 Produkte zu meep" },
};
